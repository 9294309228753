import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .mtk9 { color: #F07178; }
  .material-theme .mtk4 { color: #546E7A; }
  .material-theme .mtk6 { color: #F78C6C; }
  .material-theme .mtk5 { color: #FF9CAC; }
  .material-theme .mtk7 { color: #FFCB6B; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p>{`So I was recently doing some performance optimizations on a website, ensuring it loads as fast as possible, and that Search Engines like Google prioritize it over the competition.`}</p>
    <p>{`I came across the "Add Expires headers" recommendation and was sad to realise we only scored "F" (i.e. 1/100 points):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c9da140bb6e2c7589393f63d526e2866/2bef9/image-1024x461.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgElEQVQoz22RaVLbQBCFdf9DpDgMgUBYAyFhMd6wbNloNKtkzfaobgnzB1V91dPLvJ5uFUopVNsd3muBttsjhPAtUkr0fY+U0hjzsNai6zr0/R5CCM4XOyEwXVWYr7eYlxUa2UBpCaXkYLWElALGaL7gg0frLJSoIZoaQtSQSqKRAtYZFFRkjBmxUFozWhtIpdEozbmUIpAzck4AMjKG7/hF4uhkgx8/17hbWRQ+BDxuHG6WBlcLjT9vFrdLg/uVxc54NC7Ax4SY8oEwQmfdBdTW4914tD6hoJ38Wzv8elU4n2lcLwxzOR/ESfhvOUA+WWp+O0KvovsUN/uIIsSIh9LiYq5ZhLiYaZxPNRe/bFsupO59+OalcYD8lDOKECKr/559iVHnmxHK3Zf2IL4Ue7w1A3T+9Bd1B9fHr5FPJooFadynyvFeKf5ctXwm/m/cYXRaxd1oH0rHj6BdFjlnDp7SDqcaZ1PF+ySfmtBfZH+ieApq8MlT1WKyaw9NZRvwAdIIpv3GVH3qAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image 1024x461",
            "title": "image 1024x461",
            "src": "/static/c9da140bb6e2c7589393f63d526e2866/2bef9/image-1024x461.png",
            "srcSet": ["/static/c9da140bb6e2c7589393f63d526e2866/5a46d/image-1024x461.png 300w", "/static/c9da140bb6e2c7589393f63d526e2866/0a47e/image-1024x461.png 600w", "/static/c9da140bb6e2c7589393f63d526e2866/2bef9/image-1024x461.png 1024w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`It's funny that Google punishes it's own scripts, nevertheless, being the perfectionist I am, I had to come up with a solution.`}</p>
    <p>{`To my surprise it was difficult for me when researching what others have done here. Most questions are about delaying the triggering of a specific Google Tag Manager tag, but that does not prevent the gtm.js / analytics.js script from being loaded, and thus results in a lower PageSpeed score.`}</p>
    <h2>{`The problem`}</h2>
    <p>{`First of I tried to add "defer" to the script, meaning that it will first load, when everything else on the page has finished parsing. However, it didn't change much. There is also some issues that arises when you delay loading of the analytics / Google Tag Manager script (or other tracking scripts for that matter): less accurate results. Why? Because if the user leaves the page, before the script has been loaded, page views / events would not be tracked on that page.`}</p>
    <h2>{`My solution:`}</h2>
    <p>{`To ensure the most accurate tracking statistics while keeping a high PageSpeed score, I came up with the following conditions for when the Google Tag Manager script should be appended`}</p>
    <ul>
      <li parentName="ul">{`3500 milliseconds after `}<code parentName="li" {...{
          "className": "material-theme",
          "data-language": "js",
          "data-index": "0"
        }}><span parentName="code" {...{
            "className": "mtk1"
          }}>{`document`}</span></code>{` has fired its `}<inlineCode parentName="li">{`DOMContentLoaded`}</inlineCode>{` event`}</li>
      <li parentName="ul">{`When user scrolls the page (PageSpeed test does not trigger `}<inlineCode parentName="li">{`scroll`}</inlineCode>{`/`}<inlineCode parentName="li">{`click`}</inlineCode>{`/`}<inlineCode parentName="li">{`mousemove`}</inlineCode>{` events)`}</li>
      <li parentName="ul">{`When user moves the mouse on the page`}</li>
      <li parentName="ul">{`When user touches anywhere on the page (for mobile devices)`}</li>
    </ul>
    <p>{`This seemed to load the tracking script, and PageSpeed tests would not penalize me for missing "Expires Headers".`}</p>
    <p>{`Also, it would keep the amount of missing analytics to a minimum, because as soon as the user would move the mouse, scroll or interact with the page in any way, the tracking script would be initialized right away. Only scenario where the visitor would not be tracked, is if a user visits the webpage and decides to leave before ~3.5 seconds AND wasn't interacting with the website in ANY way (scrolling, moving mouse, etc). I'm not sure such a visit would have any real value to the marketing team anyway -- we'll see.`}</p>
    <p>{`However, there was still one big issue: `}<strong parentName="p">{`PageView`}</strong>{` events would not be tracked. I had to figure out why it didn't register the PageView event when script was added to the page later. If the script is added while the page is still loading, the `}<strong parentName="p">{`PageView`}</strong>{` event gets fired. However, if it's added later (say 3 seconds after page has finished loading) no events are fired. Google Tag Manager's "Preview Mode" was a great tool in helping me figure out what triggers that fired which tags.`}</p>
    <p>{`I realized PageView would be triggered on a specific event called `}<inlineCode parentName="p">{`gtm.js`}</inlineCode>{`, so my solution was to add an `}<inlineCode parentName="p">{`onLoad`}</inlineCode>{` callback to the tracking script, pushing this event manually into the `}<inlineCode parentName="p">{`dataLayer`}</inlineCode>{` object.`}</p>
    <p>{`Voila! It now works, and PageSpeed scores are higher than ever ;)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/52af70a0bb0c4747e3b2dba6d63cf955/c0255/image-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVQoz4WSy0oDQRBF52/9IBduRdCFC38iKxXEIAHjziSSefRzZpLM9GOOdPsguIgFl1NdTRW36Sq89zjnGJ3LTAohEKeJ6R/54BndkBliyLWi6zqs0SgpaK3JuXcjOU4Mgwl38OzMgeMohnFkXVtWtcncqo5NY3mvDM6HPPSv2xhDbp5vH7hbXHIzv+Dq6Rx70BTDMCDbPardU5s+U9gdte4JIX4bnX4dxBiZ+Dpv2xXPH/c8bWYsyhmD6yn6vkcriRQNRitEU2cmNU1DVdeZZVlmtW2bWVUVWlqIICzcPnasxZ5CSsly+ZabtDYorTO11iilSPeJQoicJwOJSUJKot8zXxvOrl952ViKcRxJSj97SumpiWkrjus/mxG9wzvHJy7QGX9RyAR/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image 1",
            "title": "image 1",
            "src": "/static/52af70a0bb0c4747e3b2dba6d63cf955/c0255/image-1.png",
            "srcSet": ["/static/52af70a0bb0c4747e3b2dba6d63cf955/5a46d/image-1.png 300w", "/static/52af70a0bb0c4747e3b2dba6d63cf955/0a47e/image-1.png 600w", "/static/52af70a0bb0c4747e3b2dba6d63cf955/c0255/image-1.png 920w"],
            "sizes": "(max-width: 920px) 100vw, 920px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`The code`}</h2>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "js",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`addEventListener`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`DOMContentLoaded`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`/** init gtm after 3500 seconds - this could be adjusted */`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`setTimeout`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`initGTM`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`3500`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`addEventListener`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`scroll`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` initGTMOnEvent)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`addEventListener`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`mousemove`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` initGTMOnEvent)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`addEventListener`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`touchstart`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` initGTMOnEvent)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`initGTMOnEvent`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`event`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`initGTM`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`event`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`currentTarget`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`removeEventListener`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`event`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`initGTMOnEvent`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// remove the event listener that got triggered`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`initGTM`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` (`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`window`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`gtmDidInit`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`) `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`false`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`window`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`gtmDidInit`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// flag to ensure script does not get added to DOM more than once.`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`script`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`createElement`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`script`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`script`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`text/javascript`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`script`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// ensure PageViews is always tracked (on script load)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`script`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`onload`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`dataLayer`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`push`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` event`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`gtm.js`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`gtm.start`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`':`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Date`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`getTime`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`gtm.uniqueEventId`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`':`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`};`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`script`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`https://www.googletagmanager.com/gtm.js?id=YOUR-GTM-ID-HERE`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`document`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`head`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`appendChild`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`script`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      